import { Context } from '~/context/auth';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Layout from './components/Layout';
import ScreenLoader from './components/ScreenLoader';
import { FilterProvider } from './context/filter';
import { MacroProvider } from './context/macros';
import { NavigationProvider } from './context/navigation';
import Login from './pages/Login';
import PdvOnline from './pages/PdvOnline';
import Recovery from './pages/Recovery';
import { LoadingProvider } from './context/loading';
import { TableViewManagerProvider } from './context/tableViewManager';

interface TypeTeste {
  url?: string;
}
const Application: React.FC<TypeTeste> = ({ url }) => (
  <NavigationProvider>
    <MacroProvider>
      <FilterProvider>
        <LoadingProvider>
          <TableViewManagerProvider>
            <Layout url={url} />
          </TableViewManagerProvider>
        </LoadingProvider>
      </FilterProvider>
    </MacroProvider>
  </NavigationProvider>
);

function CustomRoute({ isPrivate = false, openInNewTab = false, ...rest }) {
  const { loading, authenticated } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    if (openInNewTab) {
      if (window.opener === null || window.opener === undefined) {
        history.goBack();
        const newTab = window.open(
          window.location.origin + rest.path,
          '_blank',
        );
        if (!newTab) {
          history.push(rest.path);
        }
      } else {
        history.push(rest.path);
      }
    }
  }, [openInNewTab, rest.path, history]);

  if (loading) {
    return <ScreenLoader />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
}

const Routes: React.FC = () => {
  return (
    <Switch>
      <CustomRoute path="/" component={Login} exact />
      <CustomRoute path="/recovery/:hash" component={Recovery} exact />
      <CustomRoute
        path="/pdv-online"
        component={PdvOnline}
        exact
        isPrivate
        openInNewTab
      />

      <CustomRoute
        path="/app"
        exact
        render={() => <Application url="app" />}
        isPrivate
      />
      <CustomRoute
        path="/controle-de-acesso"
        exact
        render={() => <Application url="controle-de-acesso" />}
        isPrivate
      />
      <CustomRoute
        path="/usuario"
        exact
        render={() => <Application url="usuario" />}
        isPrivate
      />
      <CustomRoute
        path="/loja"
        exact
        render={() => <Application url="loja" />}
        isPrivate
      />
      <CustomRoute
        path="/teste"
        exact
        render={() => <Application url="teste" />}
        isPrivate
      />
      <CustomRoute
        path="/pdv"
        exact
        render={() => <Application url="pdv" />}
        isPrivate
      />
      <CustomRoute
        path="/tributacao"
        exact
        render={() => <Application url="tributacao" />}
        isPrivate
      />
      <CustomRoute
        path="/operador-de-caixa"
        exact
        render={() => <Application url="operador-de-caixa" />}
        isPrivate
      />
      <CustomRoute
        path="/serie-de-nf"
        exact
        render={() => <Application url="serie-de-nf" />}
        isPrivate
      />
      <CustomRoute
        path="/cest"
        exact
        render={() => <Application url="cest" />}
        isPrivate
      />
      <CustomRoute
        path="/componente"
        exact
        render={() => <Application url="componente" />}
        isPrivate
      />
      <CustomRoute
        path="/ncm"
        exact
        render={() => <Application url="ncm" />}
        isPrivate
      />
      <CustomRoute
        path="/departamento"
        exact
        render={() => <Application url="departamento" />}
        isPrivate
      />
      <CustomRoute
        path="/cliente"
        exact
        render={() => <Application url="cliente" />}
        isPrivate
      />
      <CustomRoute
        path="/tipo-de-ajuste-de-estoque"
        exact
        render={() => <Application url="tipo-de-ajuste-de-estoque" />}
        isPrivate
      />
      <CustomRoute
        path="/grupo-de-empresa"
        exact
        render={() => <Application url="grupo-de-empresa" />}
        isPrivate
      />
      <CustomRoute
        path="/fornecedor"
        exact
        render={() => <Application url="fornecedor" />}
        isPrivate
      />
      <CustomRoute
        path="/regras-de-loja"
        exact
        render={() => <Application url="regras-de-loja" />}
        isPrivate
      />
      <CustomRoute
        path="/regras-de-empresa"
        exact
        render={() => <Application url="regras-de-empresa" />}
        isPrivate
      />
      <CustomRoute
        path="/tabela-de-precos"
        exact
        render={() => <Application url="tabela-de-precos" />}
        isPrivate
      />
      <CustomRoute
        path="/produto"
        exact
        render={() => <Application url="produto" />}
        isPrivate
      />
      <CustomRoute
        path="/similares"
        exact
        render={() => <Application url="similares" />}
        isPrivate
      />
      <CustomRoute
        path="/ajuste-de-estoque"
        exact
        render={() => <Application url="ajuste-de-estoque" />}
        isPrivate
      />
      <CustomRoute
        path="/check-list"
        exact
        render={() => <Application url="check-list" />}
        isPrivate
      />
      <CustomRoute
        path="/mix-de-produto"
        exact
        render={() => <Application url="mix-de-produto" />}
        isPrivate
      />
      <CustomRoute
        path="/tara"
        exact
        render={() => <Application url="tara" />}
        isPrivate
      />
      <CustomRoute
        path="/categoria-financeira"
        exact
        render={() => <Application url="categoria-financeira" />}
        isPrivate
      />
      <CustomRoute
        path="/informacao-nutricional"
        exact
        render={() => <Application url="informacao-nutricional" />}
        isPrivate
      />
      <CustomRoute
        path="/receita"
        exact
        render={() => <Application url="receita" />}
        isPrivate
      />
      <CustomRoute
        path="/alteracao-de-preco"
        exact
        render={() => <Application url="alteracao-de-preco" />}
        isPrivate
      />
      <CustomRoute
        path="/finalizadora"
        exact
        render={() => <Application url="finalizadora" />}
        isPrivate
      />
      <CustomRoute
        path="/conta-bancaria"
        exact
        render={() => <Application url="conta-bancaria" />}
        isPrivate
      />
      <CustomRoute
        path="/certificado-digital"
        exact
        render={() => <Application url="certificado-digital" />}
        isPrivate
      />
      <CustomRoute
        path="/banco"
        exact
        render={() => <Application url="banco" />}
        isPrivate
      />
      <CustomRoute
        path="/carga"
        exact
        render={() => <Application url="carga" />}
        isPrivate
      />
      <CustomRoute
        path="/programacao-de-oferta"
        exact
        render={() => <Application url="programacao-de-oferta" />}
        isPrivate
      />
      <CustomRoute
        path="/contas-a-receber"
        exact
        render={() => <Application url="contas-a-receber" />}
        isPrivate
      />
      <CustomRoute
        path="/emissao-nfe"
        exact
        render={() => <Application url="emissao-nfe" />}
        isPrivate
      />
      <CustomRoute
        path="/feriado"
        exact
        render={() => <Application url="feriado" />}
        isPrivate
      />
      <CustomRoute
        path="/configuracao-de-e-mail"
        exact
        render={() => <Application url="configuracao-de-e-mail" />}
        isPrivate
      />
      <CustomRoute
        path="/contas-a-pagar"
        exact
        render={() => <Application url="contas-a-pagar" />}
        isPrivate
      />

      <CustomRoute
        path="/aceite-de-pagamento"
        exact
        render={() => <Application url="aceite-de-pagamento" />}
        isPrivate
      />
      <CustomRoute
        path="/vincular-email"
        exact
        render={() => <Application url="vincular-email" />}
        isPrivate
      />
      <CustomRoute
        path="/cidade"
        exact
        render={() => <Application url="cidade" />}
        isPrivate
      />
      <CustomRoute
        path="/quitacao-em-lote"
        exact
        render={() => <Application url="quitacao-em-lote" />}
        isPrivate
      />
      <CustomRoute
        path="/perfil-de-documentos"
        exact
        render={() => <Application url="perfil-de-documentos" />}
        isPrivate
      />
      <CustomRoute
        path="/validador-de-xml"
        exact
        render={() => <Application url="validador-de-xml" />}
        isPrivate
      />
      <CustomRoute
        path="/teste-de-impressao"
        exact
        render={() => <Application url="teste-de-impressao" />}
        isPrivate
      />
      <CustomRoute
        path="/entrada-de-nfe"
        exact
        render={() => <Application url="entrada-de-nfe" />}
        isPrivate
      />
      <CustomRoute
        path="/monitor-de-recepcao-de-xmls"
        exact
        render={() => <Application url="monitor-de-recepcao-de-xmls" />}
        isPrivate
      />
      <CustomRoute
        path="/status-pdv"
        exact
        render={() => <Application url="status-pdv" />}
        isPrivate
      />
      <CustomRoute
        path="/testando"
        exact
        render={() => <Application url="testando" />}
        isPrivate
      />
      <CustomRoute
        path="/retencoes-tributarias"
        exact
        render={() => <Application url="retencoes-tributarias" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-de-logs-de-nfes-de-entrada"
        exact
        render={() => (
          <Application url="relatorio-de-logs-de-nfes-de-entrada" />
        )}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-de-logs-de-nfes-de-saida"
        exact
        render={() => <Application url="relatorio-de-logs-de-nfes-de-saida" />}
        isPrivate
      />
      <CustomRoute
        path="/catalogo-ncm"
        exact
        render={() => <Application url="catalogo-ncm" />}
        isPrivate
      />
      <CustomRoute
        path="/classificacao-parceiro"
        exact
        render={() => <Application url="classificacao-parceiro" />}
        isPrivate
      />
      <CustomRoute
        path="/tipo-de-dados"
        exact
        render={() => <Application url="tipo-de-dados" />}
        isPrivate
      />
      <CustomRoute
        path="/tipo-item"
        exact
        render={() => <Application url="tipo-item" />}
        isPrivate
      />
      <CustomRoute
        path="/condicao"
        exact
        render={() => <Application url="condicao" />}
        isPrivate
      />
      <CustomRoute
        path="/modulo"
        exact
        render={() => <Application url="modulo" />}
        isPrivate
      />
      <CustomRoute
        path="/grupo-de-aplicacao"
        exact
        render={() => <Application url="grupo-de-aplicacao" />}
        isPrivate
      />
      <CustomRoute
        path="/interface"
        exact
        render={() => <Application url="interface" />}
        isPrivate
      />
      <CustomRoute
        path="/modelo-etiqueta"
        exact
        render={() => <Application url="modelo-etiqueta" />}
        isPrivate
      />
      <CustomRoute
        path="/processamento-manual-xml"
        exact
        render={() => <Application url="processamento-manual-xml" />}
        isPrivate
      />
      <CustomRoute
        path="/central-de-liberacao-de-tokens"
        exact
        render={() => <Application url="central-de-liberacao-de-tokens" />}
        isPrivate
      />
      <CustomRoute
        path="/tipo-informacao"
        exact
        render={() => <Application url="tipo-informacao" />}
        isPrivate
      />
      <CustomRoute
        path="/aplicacao"
        exact
        render={() => <Application url="aplicacao" />}
        isPrivate
      />
      <CustomRoute
        path="/cfop"
        exact
        render={() => <Application url="cfop" />}
        isPrivate
      />
      <CustomRoute
        path="/pais"
        exact
        render={() => <Application url="pais" />}
        isPrivate
      />
      <CustomRoute
        path="/ajuste-margem-produto"
        exact
        render={() => <Application url="ajuste-margem-produto" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-de-logs-de-cargas"
        exact
        render={() => <Application url="relatorio-de-logs-de-cargas" />}
        isPrivate
      />
      <CustomRoute
        path="/pauta"
        exact
        render={() => <Application url="pauta" />}
        isPrivate
      />

      <CustomRoute
        path="/central-notificacao"
        exact
        render={() => <Application url="central-notificacao" />}
        isPrivate
      />
      <CustomRoute
        path="/devolucao-e-saida-nf-forn"
        exact
        render={() => <Application url="devolucao-e-saida-nf-forn" />}
        isPrivate
      />
      <CustomRoute
        path="/central-de-liberação-de-terminais"
        exact
        render={() => <Application url="central-de-liberação-de-terminais" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-vendas"
        exact
        render={() => <Application url="relatorio-vendas" />}
        isPrivate
      />
      <CustomRoute
        path="/lancamento-nf-de-outras-operacoes"
        exact
        render={() => <Application url="lancamento-nf-de-outras-operacoes" />}
        isPrivate
      />
      <CustomRoute
        path="/grupo-etiqueta"
        exact
        render={() => <Application url="grupo-etiqueta" />}
        isPrivate
      />
      <CustomRoute
        path="/transportadora"
        exact
        render={() => <Application url="transportadora" />}
        isPrivate
      />
      <CustomRoute
        path="/cadastro-de-decomposicao"
        exact
        render={() => <Application url="cadastro-de-decomposicao" />}
        isPrivate
      />
      <CustomRoute
        path="/cancelamento-nf"
        exact
        render={() => <Application url="cancelamento-nf" />}
        isPrivate
      />
      <CustomRoute
        path="/alteracao-precos-entrada-fornecedor"
        exact
        render={() => <Application url="alteracao-precos-entrada-fornecedor" />}
        isPrivate
      />
      <CustomRoute
        path="/cadastro-de-tela"
        exact
        render={() => <Application url="cadastro-de-tela" />}
        isPrivate
      />
      <CustomRoute
        path="/edicao-conta"
        exact
        render={() => <Application url="edicao-conta" />}
        isPrivate
      />
      <CustomRoute
        path="/manutencao-fiscal"
        exact
        render={() => <Application url="manutencao-fiscal" />}
        isPrivate
      />
      <CustomRoute
        path="/alteracao-custo-entrada-nf-bonificada"
        exact
        render={() => (
          <Application url="alteracao-custo-entrada-nf-bonificada" />
        )}
        isPrivate
      />
      <CustomRoute
        path="/lancamento-caixa"
        exact
        render={() => <Application url="lancamento-caixa" />}
        isPrivate
      />
      <CustomRoute
        path="/tipo-movimento"
        exact
        render={() => <Application url="tipo-movimento" />}
        isPrivate
      />
      <CustomRoute
        path="/conferencia-tesouraria"
        exact
        render={() => <Application url="conferencia-tesouraria" />}
        isPrivate
      />
      <CustomRoute
        path="/inutilizacao-nfe"
        exact
        render={() => <Application url="inutilizacao-nfe" />}
      />
      <CustomRoute
        path="/liberacao-tesouraria"
        exact
        render={() => <Application url="liberacao-tesouraria" />}
        isPrivate
      />
      <CustomRoute
        path="/marcas"
        exact
        render={() => <Application url="marcas" />}
        isPrivate
      />
      <CustomRoute
        path="/monitor-de-api-de-integracao"
        exact
        render={() => <Application url="monitor-de-api-de-integracao" />}
        isPrivate
      />
      <CustomRoute
        path="/exportacao-escrita-fiscal"
        exact
        render={() => <Application url="exportacao-escrita-fiscal" />}
        isPrivate
      />
      <CustomRoute
        path="/sped-fiscal"
        exact
        render={() => <Application url="sped-fiscal" />}
        isPrivate
      />
      <CustomRoute
        path="/gia"
        exact
        render={() => <Application url="gia" />}
        isPrivate
      />
      <CustomRoute
        path="/unidade"
        exact
        render={() => <Application url="unidade" />}
        isPrivate
      />
      <CustomRoute
        path="/sped-efd-contribuicoes"
        exact
        render={() => <Application url="sped-efd-contribuicoes" />}
        isPrivate
      />
      <CustomRoute
        path="/conferencia-venda-sat-nfce"
        exact
        render={() => <Application url="conferencia-venda-sat-nfce" />}
        isPrivate
      />
      <CustomRoute
        path="/alteracao-de-preco-por-item"
        exact
        render={() => <Application url="alteracao-de-preco-por-item" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-auditoria-usuarios"
        exact
        render={() => <Application url="relatorio-auditoria-usuarios" />}
        isPrivate
      />
      <CustomRoute
        path="/informacao-complementar"
        exact
        render={() => <Application url="informacao-complementar" />}
        isPrivate
      />
      <CustomRoute
        path="/fechamento-fiscal"
        exact
        render={() => <Application url="fechamento-fiscal" />}
        isPrivate
      />
      <CustomRoute
        path="/reprocessa-pis-cofins"
        exact
        render={() => <Application url="reprocessa-pis-cofins" />}
        isPrivate
      />
      <CustomRoute
        path="/apuracao-pis-cofins"
        exact
        render={() => <Application url="apuracao-pis-cofins" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-vendas-por-dia"
        exact
        render={() => <Application url="relatorio-vendas-por-dia" />}
        isPrivate
      />
      <CustomRoute
        path="/registro-inventario"
        exact
        render={() => <Application url="registro-inventario" />}
        isPrivate
      />
      <CustomRoute
        path="/impressao-etiqueta"
        exact
        render={() => <Application url="impressao-etiqueta" />}
        isPrivate
      />
      <CustomRoute
        path="/apuracao-icms"
        exact
        render={() => <Application url="apuracao-icms" />}
        isPrivate
      />
      <CustomRoute
        path="/relatorio-diferenca-lancamentos"
        exact
        render={() => <Application url="relatorio-diferenca-lancamentos" />}
        isPrivate
      />
      <Redirect to="/app" />
    </Switch>
  );
};

export default Routes;
